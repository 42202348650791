import { createGlobalStyle } from 'styled-components';
import SpecialElite from './SpecialElite.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'Special Elite';
        src: local('Special Elite'), local('SpecialElite'),
        url(${SpecialElite}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;