import styled from 'styled-components';

const StyledPage = styled.div<{
  $closed: boolean;
  $onRight: boolean;
  $color?: string;
  $ZIndex: number;
  $index: number;
  $direction: 'next' | 'back';
  $totalPages: number;
}>`
  width: ${(props) => (props.$closed ? '100%' : '50%')};
  height: 100%;
  background: ${(props) => props.$color ?? 'white'};
  cursor: ${(props) =>
    props.$index === props.$totalPages - 1 && props.$totalPages % 2 === 1
      ? 'default'
      : 'pointer'};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;

  position: absolute;
  right: 0;
  z-index: ${(props) => props.$ZIndex};

  transition: transform 0.5s 0.2s, z-index 0s 0.35s, width 0.3s 0s,
    box-shadow 0.7s 0.3s;

  ${(props) =>
    props.$closed
      ? `
         box-shadow: 0 20px 38px -20px black;
        `
      : ''}

  transform-origin: 0 50%;
  ${(props) =>
    !props.$onRight
      ? `
        transform: rotateY(-180deg);
        `
      : ``}
`;

const BindShadow = styled.div<{ $onRight: boolean }>`
  box-shadow: inset 20px 0 40px -45px #222222;
  height: 100%;
  width: 20px;
  position: absolute;
  top: 0;
  transition: opacity 0s 0.3s;

  ${(props) => (!props.$onRight ? 'opacity: 0' : '')}
`;

const ChildRotate = styled.div<{
  $onRight: boolean;
  $index: number;
  $direction: 'next' | 'back';
}>`
  width: 100%;
  height: 100%;
  transition: transform 0s 0.2s;

  ${(props) =>
    props.$index % 2 === 1
      ? `
      transform: rotateY(180deg);
        `
      : ``}
`;

const Page = ({
  leftOpenPage,
  index,
  color,
  totalPages,
  children,
  direction,
  handleNext,
  handleBack,
}: {
  leftOpenPage: number;
  index: number;
  color?: string;
  totalPages: number;
  children?: React.ReactNode;
  direction: 'next' | 'back';
  handleNext: () => void;
  handleBack: () => void;
}): JSX.Element => {
  const onRight = index > leftOpenPage;
  const pageZIndex = onRight ? totalPages - index : index;

  return (
    <>
      <StyledPage
        $closed={leftOpenPage === -1}
        $direction={direction}
        className={`index-${index}`}
        $onRight={onRight}
        $color={color}
        $ZIndex={pageZIndex}
        $totalPages={totalPages}
        $index={index}
        onClick={() => {
          // If last page
          if (index >= totalPages) {
            return;
          }
          if (onRight) {
            handleNext();
          } else {
            handleBack();
          }
        }}
      >
        <ChildRotate $direction={direction} $onRight={onRight} $index={index}>
          {children}
        </ChildRotate>
        <BindShadow $onRight={onRight} />
      </StyledPage>
    </>
  );
};

export default Page;
