import { styled } from 'styled-components';

const TurnDevicePrompt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-size: xx-large;
  color: white;

  animation: bounce 2s infinite;

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: ease-in-out;
    }
    50% {
      transform: translateY(0);
      animation-timing-function: ease-in-out;
    }
  }
`;

export default TurnDevicePrompt;
