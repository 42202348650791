import styled from 'styled-components';
import Book from './Book';
import GlobalFonts from './fonts/fonts';

const Container = styled.div`
  font-family: 'Special Elite';
  width: 100%;
  height: 100%;
`;

function App() {
  return (
    <Container>
      <GlobalFonts />
      <Book />
    </Container>
  );
}

export default App;
