import { styled } from 'styled-components';

export const TableOfContents = styled.div<{ $breakpoint: string }>`
  padding: 4em 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  letter-spacing: 2px;
  align-items: center;
  font-size: clamp(4px, 3.2vh, 20px);
`;

export const TableOfContentsLink = styled.li`
  cursor: pointer;
  margin: 1rem 0;
  &:hover {
    text-decoration: underline;
  }
`;
