import React, { useState } from 'react';
import Page from './Page';
import logo from './assets/logo.png';
import logo2 from './assets/logo_2.png';
import sketchbook1 from './assets/sketchbook 1.png';
import sketchbook2 from './assets/sketchbook 2.png';
import sketchbook3 from './assets/sketchbook 3.png';
import liamPage1 from './assets/liam-page-1.png';
import greySpecialP1 from './assets/grey-special-p1.png';
import greySpecialP2 from './assets/grey-special-p2.png';
import IMG2583 from './assets/IMG_2583.jpeg';
import Refresh from './assets/refresh.svg';
import { styled } from 'styled-components';
import { useBreakpoint } from './use-breakpoint';
import { useOrientation } from 'react-use';
import TurnDevicePrompt from './turn-device-prompt';
import { TableOfContents, TableOfContentsLink } from './table-of-contents';
import Background from './background';

const StyledPages = styled.div<{ $closed: boolean; $breakpoint: string }>`
  display: flex;

  transition: width 0.35s 0s, max-width 0.35s 0s, box-shadow 0.7s 0.3s;

  height: calc(100% - 50px);
  max-height: 850px;

  ${(props) =>
    props.$closed
      ? `
      width: calc((100% - 50px)/2);
      max-width: 850px;

      `
      : `
        width: calc(100% - 50px);
        max-width: 1700px;
        box-shadow: 0 41px 38px -20px black;

  `}
  perspective: 2500px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-color: #553f28;
`;

const Book = () => {
  const [leftOpenPage, setLeftOpenPage] = useState(-1);
  const [flipDirection, setFlipDirection] = useState<'next' | 'back'>('next');
  const breakpoint = useBreakpoint();
  const orientationState = useOrientation();
  const shouldShowBook =
    orientationState.type === 'landscape-primary' || breakpoint !== 'sm';

  const content: Array<React.ReactNode> = React.useMemo(
    () => [
      <Background src={sketchbook1} />,
      <Background src={logo} $objectFit="contain" />,
      <TableOfContents $breakpoint={breakpoint}>
        <h1>Contents</h1>
        <ol>
          <TableOfContentsLink
            onClick={(e) => {
              e.stopPropagation();
              setLeftOpenPage(3);
            }}
          >
            Sketches, Paintings
          </TableOfContentsLink>
          <TableOfContentsLink
            onClick={(e) => {
              e.stopPropagation();
              setLeftOpenPage(5);
            }}
          >
            Live art
          </TableOfContentsLink>
        </ol>
      </TableOfContents>,
      <Background src={sketchbook2} />,
      <Background src={sketchbook3} />,
      <Background src={logo2} />,
      <Background src={liamPage1} />,
      <Background src={greySpecialP1} />,
      <Background src={greySpecialP2} />,
      <Background src={IMG2583} />,
    ],
    [breakpoint]
  );

  const handleNext = () => {
    setLeftOpenPage(leftOpenPage + 2);
    setFlipDirection('next');
  };

  const handleBack = () => {
    setLeftOpenPage(leftOpenPage - 2);
    setFlipDirection('back');
  };

  return (
    <Wrapper>
      {!shouldShowBook && (
        <TurnDevicePrompt>
          Rotate to enter...
          <img
            style={{ width: 100, height: 100 }}
            src={Refresh}
            alt="Rotate"
          ></img>
        </TurnDevicePrompt>
      )}
      {shouldShowBook && (
        <StyledPages
          $closed={leftOpenPage === -1}
          $breakpoint={breakpoint}
          className="StyledPages"
        >
          {content.map((page, i) => (
            <Page
              key={i}
              index={i}
              leftOpenPage={leftOpenPage}
              totalPages={content.length}
              direction={flipDirection}
              handleBack={handleBack}
              handleNext={handleNext}
            >
              {page}
            </Page>
          ))}
        </StyledPages>
      )}
    </Wrapper>
  );
};

export default Book;
